import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from '..';
import { SnackBar } from '../../utility/snackBar';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard  {
	constructor(private userService: UserService, private snackBar: SnackBar, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot) {
		// change debug to false in dev environment if you want to test authorization
		if (environment.debug) return true;

		let isAuthorized = false;

		const expectedRoles = route.data.expectedRoles;
		const userProfile = this.userService.getUserProfile;

		if (!userProfile.role) {
			return true;
		}

		isAuthorized = expectedRoles.findIndex((role) => role === userProfile.role) > -1;
		if (isAuthorized) return true;

		if (!expectedRoles || !isAuthorized) {
			this.snackBar.openSnackBarError('UNAUTHORIZED');
			this.router.navigate(['/dashboard']);
			return false;
		}

		return false;
	}
}
